<script lang="ts">
  import Clickable from "../components/core/Clickable.svelte";

  import SpriteRenderer from "../components/core/SpriteRenderer.svelte";
  import FloatingObject from "../components/FloatingObject.svelte";
  import ParallaxObject from "../components/ParallaxObject.svelte";
  import Shimmer from "../components/Shimmer.svelte";
  import { audioDatabase, imageDatabase } from "../data/asset-database";
  import type { HTMLScalableImageElement } from "../data/asset-database";
  import { activeStory } from "../contexts/interactive-context";

  const z = 30;

  type WTF = {
    x: number;
    y: number;
    asset: HTMLScalableImageElement;
  };

  let wtfffAudioIndex = 0;
  const wtfffAudio = [
    audioDatabase.wtfff03Audio,
    audioDatabase.wtfff04Audio,
    audioDatabase.wtfff06Audio,
    audioDatabase.wtfff01Audio,
    audioDatabase.wtfff07Audio,
    audioDatabase.wtfff08Audio,
    audioDatabase.wtfff05Audio,
    audioDatabase.wtfff09Audio,
    audioDatabase.wtfff10Audio,
  ];

  const onWTFFF = () => {
    if (!$activeStory) {
      wtfffAudio[wtfffAudioIndex].play();
      if (wtfffAudioIndex >= wtfffAudio.length - 1) {
        wtfffAudioIndex = 0;
      } else {
        wtfffAudioIndex++;
      }
      return true;
    }
    return false;
  };

  let wtfs: WTF[] = [
    { x: 770, y: 498, asset: imageDatabase.wtfffRight },
    { x: 2625, y: 400, asset: imageDatabase.wtfffLeft },
    { x: 1809, y: 1303, asset: imageDatabase.wtfffRight },
    { x: 2475, y: 1100, asset: imageDatabase.wtfffLeft },
    { x: 3135, y: 1155, asset: imageDatabase.wtfffLeft },
    { x: 2300, y: 1500, asset: imageDatabase.wtfffLeft },
    { x: 1664, y: 1684, asset: imageDatabase.wtfffRight },
    { x: 520, y: 1880, asset: imageDatabase.wtfffRight },
    { x: 1765, y: 2124, asset: imageDatabase.wtfffRight },
    { x: 2215, y: 1791, asset: imageDatabase.wtfffLeft },
    { x: 3250, y: 2220, asset: imageDatabase.wtfffLeft },
    { x: 700, y: 2480, asset: imageDatabase.wtfffRight },
    { x: 1880, y: 2950, asset: imageDatabase.wtfffRight },
  ];
</script>

{#each wtfs as wtf}
  <FloatingObject x={wtf.x} y={wtf.y} let:x={floatX} let:y={floatY}>
    <Shimmer scaleSpeed={6} maxScaleOffset={0.1} let:scale>
      <ParallaxObject x={floatX} y={floatY} {z} let:x={laxX} let:y={laxY}>
        <Clickable
          onClick={onWTFFF}
          x={laxX}
          y={laxY}
          width={wtf.asset.width * wtf.asset.scale * scale}
          height={wtf.asset.height * wtf.asset.scale * scale}
          let:scale={clickScale}
        >
          <SpriteRenderer
            sprite={wtf.asset}
            x={laxX}
            y={laxY}
            scale={scale * clickScale}
            roundPixels={false}
          />
        </Clickable>
      </ParallaxObject>
    </Shimmer>
  </FloatingObject>
{/each}
