<script lang="ts">
  import { onMount } from "svelte";
  import { trackVideoEvent } from "../analytics";
  import VideoRenderer from "../components/core/VideoRenderer.svelte";
  import {
    closestStory,
    finishedStories,
    introDone,
  } from "../contexts/interactive-context";
  import type { Story, StoryID } from "../types/story";
  import { generateCachedImage } from "../utils/cached-frame";
  import EasingHelper from "../utils/easing-helper";

  export let story: Story;
  let firstVideoFrame: ImageBitmap | HTMLImageElement | null = null;

  function onVideoEnded() {
    const newSet = new Set($finishedStories);
    newSet.add(story.id);
    $finishedStories = newSet;
  }

  onMount(() => {
    generateCachedImage(story.firstFrame)
      .then((frame) => (firstVideoFrame = frame))
      .catch(() => {});
    story.videoTag.addEventListener("ended", onVideoEnded);

    trackEvent("play");
    trackEvent("pause");
    trackEvent("ended");
  });

  let loopOpacity = 1;
  let videoOpacity = 0;
  let loopEnabled = true;

  const unsubscribeClosestStory = closestStory.subscribe(onClosestStoryChanged);

  function onClosestStoryChanged(name: StoryID | null) {
    if (name == story.id) {
      if (!story.loopVideoTag.getAttribute("loop")) {
        story.loopVideoTag.setAttribute("loop", "loop");
      }
      story.loopVideoTag.play().catch(console.warn);
    } else {
      story.loopVideoTag.pause();
    }
  }

  function trackEvent(event: string) {
    story.videoTag.addEventListener(event, () => {
      // On iOS videos are played / paused in the intro, so skip this play event
      if (!$introDone) {
        return;
      }

      trackVideoEvent(story, event);
    });
  }

  story.videoTag.addEventListener("play", onStoryPlaybackStarted);

  function unloadVideo() {
    loopEnabled = false;
    story.loopVideoTag.src = "";
    story.loopVideoTag.load();
    story.loopVideoTag.removeAttribute("src"); // empty source
    story.loopVideoTag.remove();
  }

  function onStoryPlaybackStarted() {
    // On iOS videos are played / paused in the intro, so skip this play event
    if (!$introDone) {
      return;
    }

    unsubscribeClosestStory();
    story.videoTag.removeEventListener("play", onStoryPlaybackStarted);

    story.loopVideoTag.pause();

    new EasingHelper<number>(
      0,
      1,
      1,
      "easeInOutSine",
      (x) => (videoOpacity = x),
      () => {
        new EasingHelper<number>(
          0,
          1,
          0.5,
          "easeInSine",
          (x) => (loopOpacity = 1 - x),
          unloadVideo
        ).run();
      }
    ).run();
  }
</script>

{#if loopEnabled}
  <VideoRenderer
    video={story.loopVideoTag}
    firstFrame={firstVideoFrame}
    x={story.location.x}
    y={story.location.y}
    opacity={loopOpacity}
  />
{/if}

<VideoRenderer
  video={story.videoTag}
  firstFrame={firstVideoFrame}
  x={story.location.x}
  y={story.location.y}
  opacity={videoOpacity}
/>
