export const entities = [];
class Entities {
    add(renderable) {
        this.remove(renderable);
        entities.push(renderable);
    }
    remove(renderable) {
        const index = entities.indexOf(renderable);
        if (index >= 0)
            entities.splice(index, 1);
    }
}
export default new Entities();
