export async function generateCachedVideoFrame(video) {
    if ("createImageBitmap" in window) {
        try {
            return createImageBitmap(video);
        }
        catch (err) { }
    }
    return video;
}
export async function generateCachedImage(image) {
    if ("createImageBitmap" in window) {
        try {
            return createImageBitmap(image);
        }
        catch (err) { }
    }
    return image;
}
