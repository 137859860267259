<script lang="ts">
  import { onMount } from "svelte";
  import { trackUnsupported } from "../analytics";

  import { t } from "../utils/i18n";

  onMount(() => {
    trackUnsupported();
  });
</script>

<div class="unsupported">
  <p>{@html $t("browser.unsupported")}</p>
</div>

<style lang="scss">
  @import "../styles/utils.scss";

  .unsupported {
    @include interface-font;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 4;

    p {
      max-width: 400px;
      margin: 20px;
      text-align: center;
    }
  }
</style>
