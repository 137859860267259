<script type="ts">
  import { onMount } from "svelte";
  import { movableElements } from "../../contexts/debug-context";
  import rg4js from 'raygun4js';

  import interactiveStore, {
    width,
    height,
    pixelRatio,
    canvas as canvasStore,
    context as contextStore,
    time as timeStore,
    cursor,
    smallScreen,
  } from "../../contexts/interactive-context";
  import { entities } from "../../entities/entities";

  export let killOnError = true;
  export let attributes: CanvasRenderingContext2DSettings = {
    alpha: false,
  };

  let canvas: HTMLCanvasElement;
  let context: CanvasRenderingContext2D;
  let frame: number;

  $: canvasWidth = $width * $pixelRatio;
  $: canvasHeight = $height * $pixelRatio;

  onMount(init);

  function init() {
    context = canvas.getContext("2d", attributes);
    context.imageSmoothingEnabled = false;

    canvasStore.set(canvas);
    contextStore.set(context);

    entities.forEach((entity) => {
      if (entity.setup) {
        entity.setup($interactiveStore);
      }

      entity.ready = true;
    });

    onResize();

    return renderLoop((time, deltaTime) => {
      timeStore.set(time);
      render(deltaTime);
    });
  }

  function render(deltaTime: number) {
    context.save();
    context.fillStyle = "black";
    context.fillRect(0, 0, $width * $pixelRatio, $height * $pixelRatio);

    entities
      .sort((a, b) => b.priority - a.priority)
      .forEach((entity) => {
        try {
          if (!entity.mounted || !entity.ready || !entity.render) {
            return;
          }

          entity.render($interactiveStore, deltaTime);
        } catch (err) {
          rg4js("send", err);
          console.error(err);

          if (killOnError) {
            cancelAnimationFrame(frame);
            console.warn("Animation loop stopped due to an error");
          }
        }
      });

    context.restore();
  }

  function onResize() {
    width.set(canvas.parentElement.clientWidth);
    height.set(canvas.parentElement.clientHeight);
    pixelRatio.set(window.devicePixelRatio);
    smallScreen.set(
      window.matchMedia("only screen and (max-width : 480px)").matches
    );
  }

  function renderLoop(callback: (time: number, deltaTime: number) => void) {
    let time = 0;
    let lastTime = performance.now();

    (function loop() {
      frame = requestAnimationFrame(loop);

      const beginTime = performance.now();
      const deltaTime = (beginTime - lastTime) / 1000;

      lastTime = beginTime;
      time += deltaTime;
      callback(time, deltaTime);
    })();

    return () => {
      cancelAnimationFrame(frame);
    };
  }

  $: cursor;
</script>

<canvas
  bind:this={canvas}
  width={canvasWidth}
  height={canvasHeight}
  class={$cursor}
  aria-label="WTFFF!?"
/>
<svelte:window on:resize|passive={onResize} />
<slot />

{#if !$movableElements}
  <style lang="scss">
    canvas {
      cursor: url("/assets/cursors/hand-open.webp"), grab;

      &.grabbing {
        cursor: url("/assets/cursors/hand-closed.webp"), grabbing;
      }
    }
  </style>
{/if}

<style lang="scss">
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
