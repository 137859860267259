<script lang="ts">
  import { movableElements } from "../../contexts/debug-context";
  import {
    viewport,
    zoom,
  } from "../../contexts/interactive-context";
  import type {
    HTMLScalableImageElement,
    HTMLScalableVideoElement,
  } from "../../data/asset-database";
  import { entities } from "../../entities/entities";
  import { Vector2 } from "../../utils/vectors";

  import SpriteRenderer from "../core/SpriteRenderer.svelte";
  import VideoRenderer from "../core/VideoRenderer.svelte";

  let dragging = false;
  let dragStart = new Vector2();
  let dragPointer = new Vector2();
  let startPosition = new Vector2();

  let altPressed = false;
  let activeElement: SpriteRenderer | VideoRenderer | undefined;

  const offscreenCanvas =
    "OffscreenCanvas" in window
      ? new OffscreenCanvas(0, 0)
      : document.createElement("canvas");
  const offscreenContext = offscreenCanvas.getContext("2d", {
    willReadFrequently: true,
  });

  function findActiveElement(ev: MouseEvent) {
    return entities
      .map((x) => x.component)
      .filter(
        (component) =>
          component instanceof SpriteRenderer ||
          component instanceof VideoRenderer
      )
      .reverse() // Start with top rendered layer first.
      .find((component: SpriteRenderer | VideoRenderer) => {
        const { top, left } = $viewport;
        const { x, y, anchor } = component;

        let frame: HTMLScalableImageElement | HTMLScalableVideoElement;
        let width = 0;
        let height = 0;
        let scale = 1;

        if (component instanceof SpriteRenderer) {
          frame = component.sprite;
          width = frame.width;
          height = frame.height;
          scale = frame.scale;
        } else {
          frame = component.video;
          width = frame.videoWidth;
          height = frame.videoHeight;
          scale = frame.scale;
        }

        if (frame.src.indexOf("background") != -1) {
          return;
        }
        
        const mousePosX = left + ev.pageX / $zoom;
        const mousePosY = top + ev.pageY / $zoom;

        const scaledWidth = Math.round(width * scale);
        const scaledHeight = Math.round(height * scale);

        const spriteTop = Math.round(y - anchor.y * scaledHeight);
        const spriteLeft = Math.round(x - anchor.x * scaledWidth);
        const spriteBottom = spriteTop + scaledHeight;
        const spriteRight = spriteLeft + scaledWidth;

        if (
          !(
            mousePosX > spriteLeft &&
            mousePosX < spriteRight &&
            mousePosY > spriteTop &&
            mousePosY < spriteBottom
          )
        ) {
          return;
        }

        const offsetX = mousePosX - spriteLeft;
        const offsetY = mousePosY - spriteTop;

        offscreenCanvas.width = scaledWidth;
        offscreenCanvas.height = scaledHeight;
        offscreenContext.clearRect(0, 0, scaledWidth, scaledHeight);
        offscreenContext.drawImage(frame, 0, 0, scaledWidth, scaledHeight);

        const colors = offscreenContext
          .getImageData(offsetX, offsetY, 1, 1)
          .data.reduce((pixel, value) => {
            return (value += pixel);
          }, 0);

        return colors > 0;
      }) as SpriteRenderer | VideoRenderer | undefined;
  }

  function reportElementDragging(
    element: SpriteRenderer | VideoRenderer | undefined
  ) {
    if (!element) {
      return;
    }

    let frame: HTMLScalableImageElement | HTMLScalableVideoElement;

    if (element instanceof SpriteRenderer) {
      frame = element.sprite;
    } else {
      frame = element.video;
    }

    console.log(`${frame.src.substr(frame.src.lastIndexOf("/") + 1)}: ${startPosition} -> ${new Vector2(Math.round(element.x), Math.round(element.y))}`);    
  }

  function onContextMenu(e: Event) {
    if (!altPressed) {
      return;
    }

    e.preventDefault();
  }

  function onDragStart(ev: MouseEvent) {
    if (!$movableElements) {
      return;
    }

    if (ev.button !== 2 || !altPressed) {
      return;
    }

    dragging = true;
    dragStart.set(ev.pageX, ev.pageY);
    dragPointer = new Vector2();

    activeElement = findActiveElement(ev);
    if (activeElement) {
      startPosition = new Vector2(Math.round(activeElement.x), Math.round(activeElement.y))
    }
  }

  function onDragStop(_: MouseEvent) {
    if (!$movableElements) {
      return;
    }

    dragging = false;
    dragStart = new Vector2();
    dragPointer = new Vector2();

    reportElementDragging(activeElement);

    activeElement = null;
  }

  function onDragMove(ev: MouseEvent) {
    if (!$movableElements) {
      return;
    }

    if (!dragging || !altPressed || !activeElement) {
      return;
    }

    dragPointer.set(ev.pageX, ev.pageY);

    const delta = Vector2.clone(dragPointer)
      .subtractVector(dragStart)
      .divide($zoom);

    dragStart.setVector(dragPointer);

    activeElement.x += Math.round(delta.x);
    activeElement.y += Math.round(delta.y);
  }

  function onKeyDown(ev: KeyboardEvent) {
    if (!$movableElements) {
      return;
    }

    altPressed = ev.altKey;
  }

  function onKeyUp(ev: KeyboardEvent) {
    if (!$movableElements) {
      return;
    }

    altPressed = ev.altKey;
  }
</script>

<svelte:window
  on:contextmenu={onContextMenu}
  on:keydown={onKeyDown}
  on:keyup={onKeyUp}
  on:mousedown={onDragStart}
  on:mouseup={onDragStop}
  on:mousemove={onDragMove}
/>
