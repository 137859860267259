<script lang="ts">
  import {
    activeStory,
    finishedStories,
  } from "../contexts/interactive-context";
  import { panelContent } from "../contexts/panel-context";
  import { t } from "../utils/i18n";
</script>

<p
  class:show={!!$activeStory && $finishedStories.has($activeStory)}
  class:dark={!!$panelContent}
>
  {$t("interface.listened")}
</p>

<style lang="scss">
  @import "../styles/utils.scss";

  p {
    @include interface-font;
    background: transparent url("/assets/icons/checked.svg") 0 0 no-repeat;
    text-shadow: 1px 1px 2px black;
    padding: 0 0 0 rem(20);
    margin: rem(10) 0 rem(10) 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    &.show {
      opacity: 1;
    }
    @media #{$big-screen} {
      &.dark {
        opacity: 0;
      }
    }
  }
</style>
