<script lang="ts">
  import renderable from "../entities/renderable";
  import { movableElements } from "../contexts/debug-context";
  import * as EasingFunctions from "../utils/easing";

  export let x;
  export let y;

  export let minOffsetX = -15;
  export let maxOffsetX = 15;
  export let minOffsetY = -20;
  export let maxOffsetY = 20;

  export let hoveringSpeed = 10;
  
  export let easing: keyof typeof EasingFunctions = "easeInOutQuad";

  const easingFunction = EasingFunctions[easing];

  let randomOffsetX = null;
  let randomOffsetY = null;

  let hoveringX = x;
  let hoveringY = y;

  let hoveringStartX = hoveringX;
  let hoveringStartY = hoveringY;

  let hoveringTimerX = 0;
  let hoveringTimerY = 0;

  let directionX = Math.random() < 0.5;
  let directionY = Math.random() < 0.5;

  function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  renderable((_, deltaTime) => {
    if ($movableElements) {
      hoveringX = x;
      hoveringY = y;
      return;
    }

    if (!randomOffsetX) {
      hoveringStartX = hoveringX;
      randomOffsetX = (getRandomArbitrary(x + minOffsetX, x + maxOffsetX) - hoveringStartX) * (directionX ? 1 : -1);
    }

    if (!randomOffsetY) {
      hoveringStartY = hoveringY;
      randomOffsetY = (getRandomArbitrary(y + minOffsetY, y + maxOffsetY) - hoveringStartY) * (directionY ? 1 : -1);
    }

    if (hoveringTimerX < hoveringSpeed) {
      hoveringTimerX += deltaTime;
      hoveringX = easingFunction(
        Math.min(hoveringSpeed, hoveringTimerX),
        hoveringStartX,
        randomOffsetX,
        hoveringSpeed
      );
    } else {
      hoveringTimerX = 0;
      directionX = !directionX;
      randomOffsetX = null;
    }

    if (hoveringTimerY < hoveringSpeed) {
      hoveringTimerY += deltaTime;
      hoveringY = easingFunction(
        Math.min(hoveringSpeed, hoveringTimerY),
        hoveringStartY,
        randomOffsetY,
        hoveringSpeed
      );
    } else {
      hoveringTimerY = 0;
      directionY = !directionY;
      randomOffsetY = null;
    }
  });
</script>

<slot x={hoveringX} y={hoveringY} />
