import { Howl, Howler } from "howler";
import { writable } from "svelte/store";
import { StoryID } from "../types/story";
export const videoLoading = writable(false);
function markVideoAsLoading(story, loading) {
    videoLoading.set(loading);
}
const defaultVideoFormats = [
    {
        container: "webm",
        type: 'video/webm; codecs="vp09.00.10.08"',
    },
    {
        container: "mov",
        type: "video/mp4; codecs=hvc1",
    },
];
const defaultVideoSizes = [
    { width: 1440 },
    { width: 1080 },
    { width: 720, media: "" },
].map((s) => {
    var _a;
    return (Object.assign(Object.assign({}, s), { media: (_a = s.media) !== null && _a !== void 0 ? _a : generateMediaQuery(s.width, [4, 3.5, 3, 2.75, 2.5, 2, 1.5, 1]) }));
});
function generateMediaQuery(width, pixelDensities) {
    return pixelDensities.map(pd => `(min-resolution: ${pd}dppx) and (min-width: ${width / pd}px), (-webkit-min-device-pixel-ratio: ${pd}) and (min-width: ${width / pd}px)`).join(", ");
}
const videoScale = 7795 / 11811; // current background size / original export background size
const assetDatabase = {
    background: generateImageAsset("/assets/sprites/background", 1.559),
    moon_half: generateImageAsset("/assets/sprites/halve_maan", 0.6),
    moon_full: generateImageAsset("/assets/sprites/volle_maan", 0.6),
    star: generateImageAsset("/assets/sprites/star", 0.6),
    hand_open: generateImageAsset("/assets/sprites/intro/hand-open", 1),
    hand_closed: generateImageAsset("/assets/sprites/intro/hand-closed", 1),
    headphones: generateImageAsset("/assets/sprites/intro/headphones", 1),
    wtfffLeft: generateImageAsset("/assets/sprites/wtfff-left", 0.5),
    wtfffRight: generateImageAsset("/assets/sprites/wtfff-right", 0.5),
    yaraStory: generateVideoAsset("/assets/videos/yara", {
        en: "yara-en.vtt",
        nl: "yara-nl.vtt",
    }, 2276 * videoScale, StoryID.YARA),
    yaraLoop: generateVideoAsset("/assets/loops/yara", null, 2276 * videoScale),
    roosStory: generateVideoAsset("/assets/videos/roos", {
        en: "roos-en.vtt",
        nl: "roos-nl.vtt",
    }, 2400 * videoScale, StoryID.ROOS),
    roosLoop: generateVideoAsset("/assets/loops/roos", null, 2400 * videoScale),
    myriamStory: generateVideoAsset("/assets/videos/myriam", {
        en: "myriam-en.vtt",
        nl: "myriam-nl.vtt",
    }, 2620 * videoScale, StoryID.MYRIAM),
    myriamLoop: generateVideoAsset("/assets/loops/myriam", null, 2620 * videoScale),
    noahStory: generateVideoAsset("/assets/videos/noah", {
        en: "noah-en.vtt",
        nl: "noah-nl.vtt",
    }, 2741 * videoScale, StoryID.NOAH),
    noahLoop: generateVideoAsset("/assets/loops/noah", null, 2741 * videoScale),
    manonStory: generateVideoAsset("/assets/videos/manon", {
        en: "manon-en.vtt",
        nl: "manon-nl.vtt",
    }, 2854 * videoScale, StoryID.MANON),
    manonLoop: generateVideoAsset("/assets/loops/manon", null, 2854 * videoScale),
    noahFirstFrame: generateImageWithSrcsetAsset("/assets/sprites/first-frames/noah"),
    yaraFirstFrame: generateImageWithSrcsetAsset("/assets/sprites/first-frames/yara"),
    roosFirstFrame: generateImageWithSrcsetAsset("/assets/sprites/first-frames/roos"),
    manonFirstFrame: generateImageWithSrcsetAsset("/assets/sprites/first-frames/manon"),
    myriamFirstFrame: generateImageWithSrcsetAsset("/assets/sprites/first-frames/myriam"),
    backgroundAudio: generateAudioAsset("/assets/audio/background.mp3", {
        loop: true,
    }),
    activationAudio: generateAudioAsset("/assets/audio/activation.mp3"),
    preVideoAudio: generateAudioAsset("/assets/audio/fade-in.mp3"),
    clickAudio: generateAudioAsset("/assets/audio/click.mp3"),
    introAudio: generateAudioAsset("/assets/audio/intro.mp3"),
    wtfff01Audio: generateAudioAsset("/assets/audio/wtfff-01.mp3"),
    wtfff03Audio: generateAudioAsset("/assets/audio/wtfff-03.mp3"),
    wtfff04Audio: generateAudioAsset("/assets/audio/wtfff-04.mp3"),
    wtfff05Audio: generateAudioAsset("/assets/audio/wtfff-05.mp3"),
    wtfff06Audio: generateAudioAsset("/assets/audio/wtfff-06.mp3"),
    wtfff07Audio: generateAudioAsset("/assets/audio/wtfff-07.mp3"),
    wtfff08Audio: generateAudioAsset("/assets/audio/wtfff-08.mp3"),
    wtfff09Audio: generateAudioAsset("/assets/audio/wtfff-09.mp3"),
    wtfff10Audio: generateAudioAsset("/assets/audio/wtfff-10.mp3"),
};
let loaded = 0;
export const loadingProgress = writable(0);
export function loadAssets() {
    return Promise.all(Object.values(assetDatabase).map((m) => m.loader.then(() => {
        loaded = loaded + 1;
        loadingProgress.set(loaded / Object.values(assetDatabase).length);
    })));
}
export const imageDatabase = assetFilter(HTMLImageElement);
export const tileDatabase = assetFilterArray(HTMLImageElement);
export const videoDatabase = assetFilter(HTMLVideoElement);
export const audioDatabase = assetFilter(Howl);
export default Object.assign(Object.assign(Object.assign(Object.assign({}, imageDatabase), tileDatabase), videoDatabase), audioDatabase);
function assetFilter(type) {
    return Object.entries(assetDatabase).reduce((value, [key, { asset }]) => {
        if (asset instanceof type) {
            value[key] = asset;
        }
        return value;
    }, {});
}
function assetFilterArray(type) {
    return Object.entries(assetDatabase).reduce((value, [key, { asset }]) => {
        if (asset instanceof Array && asset.every((x) => x instanceof type)) {
            value[key] = asset;
        }
        return value;
    }, {});
}
function generateImageWithSrcsetAsset(src, q = 0.5) {
    const picture = document.createElement("picture");
    for (const size of defaultVideoSizes.sort((a, b) => b.width - a.width)) {
        const imageSource = document.createElement("source");
        imageSource.srcset = `${src}/${size.width}.webp`;
        imageSource.media = size.media;
        imageSource.type = "image/webp";
        picture.appendChild(imageSource);
    }
    const img = document.createElement("img");
    img.src = `${src}/720.png`;
    picture.appendChild(img);
    const loader = new Promise((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = () => {
            reject(new Error("failed to load image with src:" + src));
        };
    });
    return { asset: img, loader };
}
function generateImageAsset(src, scale = 0.5) {
    const picture = document.createElement("picture");
    const imageSource = document.createElement("source");
    imageSource.srcset = `${src}.webp`;
    imageSource.type = "image/webp";
    picture.appendChild(imageSource);
    const image = document.createElement("img");
    image.src = `${src}.png`;
    image.scale = scale;
    picture.appendChild(image);
    const loader = new Promise((resolve, reject) => {
        image.onload = () => resolve();
        image.onerror = () => {
            reject(new Error("failed to load image with src:" + src));
        };
    });
    return { asset: image, loader };
}
function generateVideoAsset(src, subtitles, originalSize = 0.5, mainStoryId = null, sources = defaultVideoFormats, sizes = defaultVideoSizes) {
    const video = document.createElement("video");
    // TODO: uncomment when merging with develop
    // const cdnSrc = /* "https://cdn.wtfff.nl" + */ src;
    const cdnSrc = "https://cdn.wtfff.nl" + src;
    for (const source of sources) {
        for (const size of sizes.sort((a, b) => b.width - a.width)) {
            const videoSource = document.createElement("source");
            videoSource.dataset.src = `${cdnSrc}/${size.width}.${source.container}`;
            videoSource.type = source.type;
            videoSource.dataset.scale = `${originalSize / size.width}`;
            if (size.media) {
                videoSource.media = size.media;
            }
            video.appendChild(videoSource);
        }
    }
    if (subtitles && mainStoryId) {
        const trackNL = document.createElement("track");
        trackNL.kind = "subtitles";
        trackNL.srclang = "nl";
        trackNL.src = "/assets/subtitles/" + subtitles.nl;
        trackNL.id = mainStoryId + "-nl";
        trackNL.track.mode = "hidden";
        const trackEN = document.createElement("track");
        trackEN.kind = "subtitles";
        trackEN.srclang = "en";
        trackEN.id = mainStoryId + "-en";
        trackEN.src = "/assets/subtitles/" + subtitles.en;
        trackEN.track.mode = "hidden";
        video.appendChild(trackNL);
        video.appendChild(trackEN);
    }
    video.scale = originalSize;
    video.autoplay = false;
    video.loop = false;
    video.playsInline = true;
    video.muted = !mainStoryId;
    video.preload = "metadata";
    if (mainStoryId) {
        video.onwaiting = () => markVideoAsLoading(mainStoryId, true);
        video.onseeking = () => markVideoAsLoading(mainStoryId, true);
        video.onseeked = () => markVideoAsLoading(mainStoryId, false);
        video.onplaying = () => markVideoAsLoading(mainStoryId, false);
        video.onpause = () => markVideoAsLoading(mainStoryId, false);
        video.onended = () => markVideoAsLoading(mainStoryId, false);
        video.onplay = () => markVideoAsLoading(mainStoryId, true);
    }
    const loader = new Promise((resolve, reject) => {
        video.addEventListener("loadedmetadata", () => resolve());
        video.onerror = () => {
            var _a, _b;
            reject(new Error("failed to load video with src:" + src + " " + ((_a = video === null || video === void 0 ? void 0 : video.error) === null || _a === void 0 ? void 0 : _a.code) + " " + ((_b = video === null || video === void 0 ? void 0 : video.error) === null || _b === void 0 ? void 0 : _b.message)));
        };
    });
    return { asset: video, loader };
}
Howler.autoSuspend = false;
function generateAudioAsset(src, options = {
    loop: false,
}) {
    const asset = new Howl({
        src,
        loop: options.loop,
        preload: "metadata",
    });
    asset.on("playerror", (error) => {
        console.warn("failed to play audio with src:" + src, error);
    });
    const loader = new Promise((resolve, reject) => {
        asset.on("load", () => resolve());
        asset.on("loaderror", (error) => {
            reject(new Error("failed to load audio with src:" + src + " " + error));
        });
    });
    return { asset, loader };
}
