import keyBy from "lodash.keyby";
import { StoryID } from "../types/story";
import { Vector2 } from "../utils/vectors";
import { imageDatabase, videoDatabase } from "./asset-database";
import MathC from "../utils/math";
export const stories = [
    {
        id: StoryID.NOAH,
        title: "Noah",
        videoTag: videoDatabase.noahStory,
        loopVideoTag: videoDatabase.noahLoop,
        firstFrame: imageDatabase.noahFirstFrame,
        centerpoint: new Vector2(1100, 1400),
        location: new Vector2(1070, 1615),
        activationRadius: 400,
        zoomRadius: 540,
        themes: [
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("00:00.000"),
                end: MathC.timeToSec("01:25.000"),
            },
            {
                title: "theme.sextortion",
                content: "theme_what",
                start: MathC.timeToSec("01:25.000"),
                end: MathC.timeToSec("02:23.000"),
            },
            {
                title: "theme.shame",
                content: "theme_help_shame",
                start: MathC.timeToSec("02:23.000"),
                end: MathC.timeToSec("02:54.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("02:54.000"),
                end: MathC.timeToSec("04:06.000"),
            },
        ],
    },
    {
        id: StoryID.YARA,
        title: "Yara",
        videoTag: videoDatabase.yaraStory,
        loopVideoTag: videoDatabase.yaraLoop,
        firstFrame: imageDatabase.yaraFirstFrame,
        centerpoint: new Vector2(1225, 2300),
        location: new Vector2(1135, 2475),
        activationRadius: 400,
        zoomRadius: 520,
        themes: [
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("00:00.000"),
                end: MathC.timeToSec("00:25.000"),
            },
            {
                title: "theme.shame",
                content: "theme_help_shame",
                start: MathC.timeToSec("00:25.000"),
                end: MathC.timeToSec("00:30.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("00:30.000"),
                end: MathC.timeToSec("00:44.000"),
            },
            {
                title: "theme.guilt",
                content: "theme_help_guilt",
                start: MathC.timeToSec("00:44.000"),
                end: MathC.timeToSec("02:57.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("02:57.000"),
                end: MathC.timeToSec("03:47.000"),
            },
        ],
    },
    {
        id: StoryID.ROOS,
        title: "Roos",
        videoTag: videoDatabase.roosStory,
        loopVideoTag: videoDatabase.roosLoop,
        firstFrame: imageDatabase.roosFirstFrame,
        centerpoint: new Vector2(2600, 1800),
        location: new Vector2(2685, 1905),
        activationRadius: 370,
        zoomRadius: 550,
        themes: [
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("00:00.000"),
                end: MathC.timeToSec("00:33.000"),
            },
            {
                title: "theme.guilt",
                content: "theme_help_guilt",
                start: MathC.timeToSec("00:33.000"),
                end: MathC.timeToSec("01:49.000"),
            },
            {
                title: "theme.fear",
                content: "theme_help_fear",
                start: MathC.timeToSec("01:49.000"),
                end: MathC.timeToSec("02:55.000"),
            },
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("02:55.000"),
                end: MathC.timeToSec("03:28.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("03:28.000"),
                end: MathC.timeToSec("03:40.000"),
            },
            {
                title: "theme.help",
                content: "theme_help",
                start: MathC.timeToSec("03:40.000"),
                end: MathC.timeToSec("04:40.000"),
            },
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("04:40.000"),
                end: MathC.timeToSec("05:11.000"),
            },
        ],
    },
    {
        id: StoryID.MANON,
        title: "Manon",
        videoTag: videoDatabase.manonStory,
        loopVideoTag: videoDatabase.manonLoop,
        firstFrame: imageDatabase.manonFirstFrame,
        centerpoint: new Vector2(2100, 2500),
        location: new Vector2(2215, 2570),
        activationRadius: 400,
        zoomRadius: 500,
        themes: [
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("00:00.000"),
                end: MathC.timeToSec("01:57.000"),
            },
            {
                title: "theme.help",
                content: "theme_help",
                start: MathC.timeToSec("01:57.000"),
                end: MathC.timeToSec("02:20.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("02:20.000"),
                end: MathC.timeToSec("02:50.000"),
            },
            {
                title: "theme.guilt",
                content: "theme_help_guilt",
                start: MathC.timeToSec("02:50.000"),
                end: MathC.timeToSec("03:04.000"),
            },
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("03:04.000"),
                end: MathC.timeToSec("03:25.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("03:25.000"),
                end: MathC.timeToSec("03:46.000"),
            },
            {
                title: "theme.help",
                content: "theme_help",
                start: MathC.timeToSec("03:46.000"),
                end: MathC.timeToSec("04:12.000"),
            },
        ],
    },
    {
        id: StoryID.MYRIAM,
        title: "Myriam",
        videoTag: videoDatabase.myriamStory,
        loopVideoTag: videoDatabase.myriamLoop,
        firstFrame: imageDatabase.myriamFirstFrame,
        centerpoint: new Vector2(2000, 900),
        location: new Vector2(2100, 1045),
        activationRadius: 400,
        zoomRadius: 550,
        themes: [
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("00:00.000"),
                end: MathC.timeToSec("01:30.000"),
            },
            {
                title: "theme.fear",
                content: "theme_help_fear",
                start: MathC.timeToSec("01:30.000"),
                end: MathC.timeToSec("03:40.000"),
            },
            {
                title: "theme.guilt",
                content: "theme_help_guilt",
                start: MathC.timeToSec("03:40.000"),
                end: MathC.timeToSec("03:49.000"),
            },
            {
                title: "theme.what",
                content: "theme_what",
                start: MathC.timeToSec("03:49.000"),
                end: MathC.timeToSec("04:17.000"),
            },
            {
                title: "theme.support",
                content: "theme_support",
                start: MathC.timeToSec("04:17.000"),
                end: MathC.timeToSec("04:52.000"),
            },
            {
                title: "theme.help",
                content: "theme_help",
                start: MathC.timeToSec("04:52.000"),
                end: MathC.timeToSec("05:09.000"),
            },
        ],
    },
];
export const storiesDictionary = keyBy(stories, "id");
