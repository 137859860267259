<script lang="ts">
  import { onMount } from "svelte";

  import type { VideoAssets } from "../../data/asset-database";
  import { videoDatabase } from "../../data/asset-database";
  import { isSafari } from "../../utils/device";

  let videoWrapper: HTMLElement;

  $: appendNewVideos(videoWrapper, videoDatabase);

  onMount(onResize);

  function appendNewVideos(wrapper: HTMLElement, value: VideoAssets) {
    if (!wrapper) {
      return;
    }

    const videos = Object.values(value);

    for (const video of videos) {
      if (document.body.contains(video)) {
        return;
      }

      wrapper.appendChild(video);
    }
  }

  function onResize() {
    for (const video of Object.values(videoDatabase)) {
      const sourceElements = video.querySelectorAll("source");
      const sources: HTMLSourceElement[] = [];

      sourceElements.forEach((source) => sources.push(source));

      let matchedMedia = null;

      for (const source of sources) {
        // we save matchedMedia, since we only want to match the first one (sources are ordered from small to high), but both of webm and mov
        // console.log(window.screen.availWidth, window.devicePixelRatio, source.dataset.src, matchMedia(source.media).matches, source.media, matchMedia(source.media))
        if (
          !(source.type.includes("video/webm") && isSafari()) &&
          matchMedia(source.media).matches &&
          (!matchedMedia || matchedMedia === source.media)
        ) {
          matchedMedia = source.media;
          source.src = source.dataset.src;
          video.scale = parseFloat(source.dataset.scale);
        } else if (source.media) {
          source.removeAttribute("src");
        }
      }
    }
  }
</script>

<section bind:this={videoWrapper} />

<style lang="scss">
  section {
    width: 10px;
    height: 10px;
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: -6;

    :global(video) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
</style>
