<svelte:options accessors={true} />

<script lang="ts">
  import { click } from "../../contexts/interactive-context";
  import EasingHelper from "../../utils/easing-helper";

  import { Vector2 } from "../../utils/vectors";

  export let onClick: Function;
  export let anchor = new Vector2(0.5, 0.5);
  export let x = 0;
  export let y = 0;
  export let width = 0;
  export let height = 0;

  let scaleEasing: EasingHelper<number> | null = null;

  function animateScale() {
    scaleEasing?.reset();
    scaleEasing = new EasingHelper(
      1,
      1.15,
      0.15,
      "easeInSine",
      undefined,
      () => {
        scaleEasing = new EasingHelper(1.15, 1, 0.15, "easeOutSine").run();
      }
    ).run();
  }

  click.subscribe((val) => {
    if (val) {
      const spriteTop = Math.round(y - anchor.y * height);
      const spriteLeft = Math.round(x - anchor.x * width);
      const spriteBottom = spriteTop + height;
      const spriteRight = spriteLeft + width;

      if (
        val.x >= spriteLeft &&
        val.y >= spriteTop &&
        val.x <= spriteRight &&
        val.y <= spriteBottom
      ) {
        if (onClick()) {
          animateScale();
        }
      }
    }
  });
</script>

<slot scale={scaleEasing?.value ?? 1} />
