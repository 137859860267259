import { onMount } from "svelte";
import { current_component } from "svelte/internal";
import Entities from "./entities";
export const key = Symbol();
export default function renderable(render) {
    const renderable = {
        ready: false,
        mounted: false,
        component: current_component,
        priority: 0,
    };
    if (render instanceof Function) {
        renderable.render = render;
    }
    else if (render) {
        if (render.render)
            renderable.render = render.render;
        if (render.setup)
            renderable.setup = render.setup;
    }
    Entities.add(renderable);
    onMount(() => {
        renderable.mounted = true;
        return () => {
            Entities.remove(renderable);
            renderable.mounted = false;
        };
    });
}
