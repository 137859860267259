<script lang="ts">
  import { storyDistance, toBecomeActiveStory } from "../contexts/interactive-context";
  import MathC from "../utils/math";
</script>

<div
  class="gradient"
  style="--opacity: {$toBecomeActiveStory ? MathC.clamp(1 - ($storyDistance / 350), 0, 1) : 0}"
/>

<style lang="scss">
  .gradient {
    pointer-events: none;
    position: fixed;
    opacity: var(--opacity);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: -15vh -15vw;
    overflow: hidden;
    background: radial-gradient(
      circle closest-side,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
</style>
