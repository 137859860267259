<script lang="ts">
  import { onMount } from "svelte";
  import { trackLoadError } from "../analytics";
  import rg4js from 'raygun4js';
  import { t } from "../utils/i18n";
  export let error: Error;

  onMount(() => {
    console.error(error);
    rg4js("send", error);
    trackLoadError();
  });
</script>

<div>
  <p>{@html $t("load.error")}</p>
</div>

<style lang="scss">
  @import "../styles/utils.scss";

  div {
    @include interface-font;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 4;

    p {
      max-width: 400px;
      margin: 20px;
      text-align: center;
    }
  }
</style>
