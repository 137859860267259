<script lang="ts">
  import { activeStory } from "../contexts/interactive-context";
</script>

<div class="shadow-canvas">
  <div class="shadow bottom" style="{$activeStory ? 'opacity: 1;' : 'opacity: 0;'}" />
</div>

<style lang="scss" scoped>
  @import "../styles/utils.scss";

  .shadow-canvas {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: inset 0 0 80px 30px black;

    .bottom {
      position: absolute;
      z-index: 1;
      background: linear-gradient(0deg, rgba(0,0,0,0.7) 20%, rgba(0,0,0,0) 100%);
      bottom: 0;
      width: 100%;
      height: 150px;
      transition: opacity 1s ease-in-out;
    }
  }
</style>
