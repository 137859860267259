<script lang="ts">
  import { onMount } from "svelte";

  const speed = 100;

  export let text: string;
  export let color: string = "#25d989";

  let hiddenIndex = 0;

  onMount(() => {
    setInterval(
      () =>
        hiddenIndex + 1 === text.length
          ? (hiddenIndex = 0)
          : (hiddenIndex = hiddenIndex + 1),
      speed
    );
  });
</script>

<div>
  <p style="color: {color}">
    {#each text as letter, index}
      <span class:hiddenText={index === hiddenIndex}>{letter}</span>
    {/each}
  </p>
</div>

<style lang="scss" scoped>
  @import "../styles/utils.scss";
  p {
    @include interface-font;
    text-align: center;
    margin: 0;

    &:first-child {
      margin-top: $page-gutter;
    }
  }
  .hiddenText {
    visibility: hidden;
  }
</style>
