import { activeStory } from "../contexts/interactive-context";
import { storiesDictionary } from "../data/stories";
let active = null;
activeStory.subscribe((val) => {
    active = val;
});
export async function playVideo(story, jumpTo = -1) {
    if (!story) {
        return;
    }
    const tag = storiesDictionary[story].videoTag;
    if (jumpTo >= 0 || (active && !tag.ended)) {
        if (jumpTo >= 0) {
            tag.currentTime = jumpTo;
        }
        await tag.play();
    }
}
