import { detect } from "detect-browser";
export const browser = detect();
export function isIOS() {
    return (browser === null || browser === void 0 ? void 0 : browser.os) === "iOS";
}
export function isTouchDevice() {
    try {
        document.createEvent("TouchEvent");
        return true;
    }
    catch (e) {
        return false;
    }
}
export function isFirefox() {
    return (browser === null || browser === void 0 ? void 0 : browser.name) === "firefox";
}
export function isUnSupportedBrowser() {
    return isFirefox();
}
export function isSafari() {
    return (browser === null || browser === void 0 ? void 0 : browser.name) === "safari" || (browser === null || browser === void 0 ? void 0 : browser.name) === "ios";
}
