<script lang="ts">
  import { frameRateEnabled } from "../contexts/debug-context";
  import renderable from "../entities/renderable";

  let text = "";

  let frames = 0;
  let prevTime = performance.now();

  renderable(() => {
    if (!$frameRateEnabled) {
      return;
    }

    let time = performance.now();
    frames++;

    if (time >= prevTime + 1000) {
      const fps = (frames * 1000) / (time - prevTime);
      text = `${fps.toFixed(1)} FPS`;
      prevTime = time;
      frames = 0;
    }
  });
</script>

{#if $frameRateEnabled}
  <div class="framerate">
    {text}
  </div>
{/if}

<style lang="scss">
  .framerate {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    color: #fff;
  }
</style>
