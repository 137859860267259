import * as EasingFunctions from "./easing";
import { Vector2 } from "./vectors";
export default class EasingHelper {
    constructor(start, end, duration, easing, valueCallback = undefined, completeCallback = undefined) {
        this.timer = 0;
        this.duration = 0;
        this.start = 0;
        this.end = 0;
        this.frame = -1;
        this.lastTime = performance.now();
        this.running = false;
        this.value = this.start;
        this.start = start;
        this.end = end;
        this.completeCallback = completeCallback;
        this.valueCallback = valueCallback;
        if (start instanceof Vector2) {
            this.value = Vector2.clone(start);
            this.distance = Vector2.clone(end).subtractVector(start);
        }
        else {
            this.value = start;
            this.distance = (end - start);
        }
        this.duration = duration;
        this.easingFunction = EasingFunctions[easing];
    }
    run() {
        this.running = true;
        this.lastTime = performance.now();
        this.frame = requestAnimationFrame(this.execute.bind(this));
        return this;
    }
    reset() {
        this.timer = 0;
        this.running = false;
        cancelAnimationFrame(this.frame);
    }
    execute(time) {
        if (this.timer >= this.duration) {
            if (this.end instanceof Vector2) {
                this.value = Vector2.clone(this.end);
            }
            else {
                this.value = this.end;
            }
            if (this.valueCallback) {
                this.valueCallback(this.value);
            }
            if (this.completeCallback) {
                this.completeCallback();
            }
            requestAnimationFrame(() => (this.running = false));
            return;
        }
        const deltaTime = (time - this.lastTime) / 1000;
        if (this.value instanceof Vector2) {
            const start = this.start;
            const distance = this.distance;
            this.value.set(this.easingFunction(this.timer, start.x, distance.x, this.duration), this.easingFunction(this.timer, start.y, distance.y, this.duration));
        }
        else {
            this.value = this.easingFunction(this.timer, this.start, this.distance, this.duration);
        }
        if (this.valueCallback) {
            this.valueCallback(this.value);
        }
        this.timer += deltaTime;
        this.lastTime = time;
        this.frame = requestAnimationFrame(this.execute.bind(this));
    }
}
