<script lang="ts">
  import renderable from "../entities/renderable";
  import { movableElements, noParallax } from "../contexts/debug-context";
  import { sceneHeight, sceneWidth } from "../contexts/interactive-context";

  export let x;
  export let y;
  export let z;

  let parallaxX = 0;
  let parallaxY = 0;

  renderable(({ viewport: { center } }) => {
    if (!$noParallax && !$movableElements) {
      parallaxX = x - ((sceneWidth / 2 - center.x) * z) / 300;
      parallaxY = y - ((sceneHeight / 2 - center.y) * z) / 300;
    } else {
      parallaxX = x;
      parallaxY = y;
    }
  });
</script>

<slot x={parallaxX} y={parallaxY} />
