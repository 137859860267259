<script type="ts">
  import { onMount } from "svelte";
  import { activeStory, introStarted } from "../contexts/interactive-context";
  import { audioDatabase } from "../data/asset-database";

  const videoPlayingBackgroundVolume = 0.15;
  const defaultBackgroundVolume = 0.6;

  let wrapper: HTMLDivElement;

  function setTargetVolume(value) {
    audioDatabase.backgroundAudio.fade(audioDatabase.backgroundAudio.volume(), value, 1);
  }

  activeStory.subscribe((val) => {
    if ($introStarted) {
      if (val) {
        setTargetVolume(videoPlayingBackgroundVolume);
      } else {
        setTargetVolume(defaultBackgroundVolume);
      }
    }
  });

  introStarted.subscribe((val) => {
    if (val) {
      setTargetVolume(defaultBackgroundVolume);
    }
  });

  onMount(() => {
    audioDatabase.backgroundAudio.volume(0);
  });
</script>

<div bind:this={wrapper} />
