import { maxZoom, minZoom, sceneHeight, sceneWidth, width, } from "../contexts/interactive-context";
class SceneSize {
    constructor() {
        width.subscribe(this.calculateSceneSize);
    }
    calculateSceneSize() {
        const minScale = Math.max(document.body.clientWidth / sceneWidth, document.body.clientHeight / sceneHeight);
        minZoom.set(minScale);
        maxZoom.set(Math.min((minScale) * 3, 1.8));
    }
}
export default new SceneSize();
