<script lang="ts">
  import renderable from "../entities/renderable";
  import { movableElements } from "../contexts/debug-context";
  import * as EasingFunctions from "../utils/easing";

  export let scaleSpeed = 0.8;
  export let opacitySpeed = 0.1;

  export let scale = 1;
  export let minOpacity = 0.7;
  export let maxOpacity = 1;
  export let maxScaleOffset = 0.05;
  export let easing: keyof typeof EasingFunctions = "easeInOutQuad";

  const easingFunction = EasingFunctions[easing];

  let opacityOffset = null;
  let scaleOffset = null;

  let opacity = 1;
  let newScale = 1;

  let opacityFrom = opacity;
  let scaleFrom = newScale;

  let opacityTimer = 0;
  let scaleTimer = 0;

  function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  renderable((_, deltaTime) => {
    if ($movableElements) {
      newScale = scale;
      return;
    }

    if (!opacityOffset) {
      opacityFrom = opacity;
      opacityOffset = getRandomArbitrary(minOpacity, maxOpacity) - opacityFrom;
    }

    if (!scaleOffset) {
      scaleFrom = newScale;
      scaleOffset = getRandomArbitrary(scale - maxScaleOffset, scale + maxScaleOffset) - scaleFrom;
    }

    if (opacityTimer < opacitySpeed) {
      opacityTimer += deltaTime;
      opacity = easingFunction(
        Math.min(opacitySpeed, opacityTimer),
        opacityFrom,
        opacityOffset,
        opacitySpeed
      );
    } else {
      opacityOffset = null;
      opacityTimer = 0;
    }

    if (scaleTimer < scaleSpeed) {
      scaleTimer += deltaTime;
      newScale = easingFunction(
        Math.min(scaleSpeed, scaleTimer),
        scaleFrom,
        scaleOffset,
        scaleSpeed
      );
    } else {
      scaleOffset = null;
      scaleTimer = 0;
    }
  });
</script>

<slot opacity={opacity} scale={newScale} />
