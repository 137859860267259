<script lang="ts">
  import VideoTitle from "./VideoTitle.svelte";
  import { storiesDictionary } from "../data/stories";
</script>

<VideoTitle story={storiesDictionary.yara} />
<VideoTitle story={storiesDictionary.roos} />
<VideoTitle story={storiesDictionary.myriam} />
<VideoTitle story={storiesDictionary.manon} />
<VideoTitle story={storiesDictionary.noah} />
