<svelte:options accessors={true} />

<script lang="ts">
  import { movableElements } from "../../contexts/debug-context";

  import type { HTMLScalableVideoElement } from "../../data/asset-database";

  import renderable from "../../entities/renderable";
  import { Vector2 } from "../../utils/vectors";

  import {
    generateCachedVideoFrame,
  } from "../../utils/cached-frame";
  import { onMount } from "svelte";

  export let video: HTMLScalableVideoElement;
  export let firstFrame: ImageBitmap | HTMLImageElement | null =
    null;

  export let opacity = 1;
  export let enabled = true;
  export let anchor = new Vector2(0.5, 0.5);
  export let x = 0;
  export let y = 0;

  let videoFrame: ImageBitmap | HTMLVideoElement | null =
    null;
  let videoSize = new Vector2();
  let frameRate = 12;

  onMount(() => {
    const interval = setInterval(() => {
      if (!enabled) {
        return;
      }

      if (video.readyState < 2) {
        return;
      }

      if ((video.paused || video.ended) && videoFrame !== null) {
        return;
      }

      generateCachedVideoFrame(video)
        .then((frame) => {
          const oldFrame = videoFrame;
          videoFrame = frame;
          if (oldFrame instanceof ImageBitmap) {
            oldFrame.close();
          }
        })
        .catch(() => {}); // Avoid complaints from missing frameData at the start
    }, 1000 / frameRate);

    return () => clearInterval(interval);
  });

  renderable({
    render({ context }) {
      if (!enabled) {
        return;
      }

      if (!video) {
        return;
      }

      const frame = videoFrame || firstFrame;

      if (!frame) {
        return;
      }

      if (!opacity) {
        return;
      }

      const offsetX = Math.round(x - anchor.x * videoSize.x);
      const offsetY = Math.round(y - anchor.y * videoSize.y);

      context.globalAlpha = opacity;
      context.drawImage(frame, offsetX, offsetY, videoSize.x, videoSize.y);
      context.globalAlpha = 1;

      if ($movableElements) {
        context.strokeStyle = "#FF0000";
        context.strokeRect(offsetX, offsetY, videoSize.x, videoSize.y);
      }
    },
    setup() {
      const { videoWidth, videoHeight, scale } = video;

      videoSize.set(
        Math.round(videoWidth * scale),
        Math.round(videoHeight * scale)
      );
    },
  });
</script>
