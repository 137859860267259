<script lang="ts">
  import type { Viewport } from "../../types/viewport";
  import {
    click,
    clickedStory,
    cursor,
    dragIntroStarted,
  } from "../../contexts/interactive-context";
  import type { Store } from "../../contexts/interactive-context";
  import { isCameraMovingByUser } from "../../contexts/interactive-context";

  import { Vector2 } from "../../utils/vectors";
  import * as EasingFunctions from "../../utils/easing";
  import renderable from "../../entities/renderable";

  import {
    width,
    height,
    zoom,
    pixelRatio,
    sceneWidth,
    sceneHeight,
    camera,
    viewport,
    introDone,
  } from "../../contexts/interactive-context";
  import EasingHelper from "../../utils/easing-helper";
  import { panelContent } from "../../contexts/panel-context";

  type InertiaItem = {
    position: Vector2;
    timestamp: number;
  };

  let scenePadding = 200 * $pixelRatio;
  let dragging = false;

  let dragStartTime = 0;
  let dragStart = new Vector2();
  let dragPointer = new Vector2();

  let inertiaList = Array<InertiaItem>();
  let inertia = new Vector2();
  let inertiaTimer = 0;
  let inertiaDuration = 0;

  let targetEaser: EasingHelper<Vector2> | null = null;

  $: halfWidth = ($width / 2) * $pixelRatio;
  $: halfHeight = ($height / 2) * $pixelRatio;

  // If canvas size changes or center point is updated, calculate new frustrum
  $: if ($width !== 0 || $height !== 0)
    viewport.set(calculateViewportFrustrum($viewport.center, $zoom));

  renderable({
    setup() {
      // Make sure that camera is always rendered as first in queue
      this.priority = 99;

      camera.set(this.component);
    },
    render(store, deltaTime) {
      const {
        context,
        zoom,
        pixelRatio,
        viewport: { center, left, top },
      } = store;

      let position = Vector2.clone(center);

      if (dragging && dragPointer.magnitude() > Number.EPSILON) {
        position = calculateDragPosition(store);
      } else if (targetEaser?.running) {
        position = calculateTargetPositionEasing();
      } else if (inertiaDuration > 0) {
        position = calculateInertiaDropoff(store, deltaTime);
      } else if (!dragging) {
        isCameraMovingByUser.set(false);
      }

      viewport.set(calculateViewportFrustrum(position, zoom));

      context.scale(zoom * pixelRatio, zoom * pixelRatio);
      context.translate(-left, -top);
    },
  });

  function resetInertia() {
    inertia.set(0, 0);
    inertiaDuration = 0;
    inertiaList = [];
  }

  export function resume() {
    isCameraMovingByUser.set(true);
    isCameraMovingByUser.set(false);
  }

  export function stop() {
    targetEaser?.reset();
    resetInertia();
  }

  export function goToPosition(
    position: Vector2,
    duration?: number,
    easing?: keyof typeof EasingFunctions,
    completeCallback?: () => void
  ) {
    if (dragging) {
      return;
    }

    const start = Vector2.clone($viewport.center);
    const end = Vector2.clone(position);

    resetInertia();
    targetEaser?.reset();
    targetEaser = new EasingHelper<Vector2>(
      start,
      end,
      duration ?? Math.abs(end.magnitude() - start.magnitude()) / 1000,
      easing ?? "easeOutSine",
      undefined,
      completeCallback
    ).run();
  }

  export function isGoingToClampPosition(position: Vector2, zoomLevel: number) {
    if (!position || !zoomLevel) {
      return true;
    }

    const clamped = clampToEdges(position, zoomLevel);

    return position.x !== clamped.x || position.y !== clamped.y;
  }

  function calculateDragPosition({ zoom, viewport: { center } }: Store) {
    const diff = Vector2.clone(dragPointer)
      .subtractVector(dragStart)
      .divide(zoom);

    // Only keep entries for 100ms
    var now = Date.now();
    while (inertiaList.length > 0) {
      if (now - inertiaList[0].timestamp <= 100) {
        break;
      }
      inertiaList.shift();
    }

    inertiaList.push({
      position: Vector2.clone(dragPointer),
      timestamp: now,
    });

    dragStart.setVector(dragPointer);

    return Vector2.clone(center).subtractVector(diff);
  }

  function calculateTargetPositionEasing() {
    return targetEaser.value;
  }

  function calculateInertiaDropoff(
    { viewport: { center } }: Store,
    deltaTime: number
  ) {
    inertiaTimer += deltaTime;

    if (inertiaTimer >= inertiaDuration) {
      isCameraMovingByUser.set(false);
      resetInertia();
      return Vector2.clone(center);
    }

    const newValue = Vector2.clone(inertia).set(
      EasingFunctions.easeOutCubic(
        inertiaTimer,
        inertia.x,
        -inertia.x,
        inertiaDuration
      ),
      EasingFunctions.easeOutCubic(
        inertiaTimer,
        inertia.y,
        -inertia.y,
        inertiaDuration
      )
    );

    return Vector2.clone(center).subtractVector(newValue);
  }

  function calculateViewportFrustrum(
    position: Vector2,
    zoomLevel: number
  ): Viewport {
    const clamped = clampToEdges(position, zoomLevel);
    const { x, y } = clamped;

    const scaledWidth = halfWidth / zoomLevel / $pixelRatio;
    const scaledHeight = halfHeight / zoomLevel / $pixelRatio;

    return {
      top: y - scaledHeight,
      left: x - scaledWidth,
      bottom: y + scaledHeight,
      right: x + scaledWidth,
      center: clamped,
    };
  }

  function clampToEdges(position: Vector2, zoomLevel: number) {
    const { x, y } = position;

    const scaledWidth = halfWidth / zoomLevel / $pixelRatio;
    const scaledHeight = halfHeight / zoomLevel / $pixelRatio;

    return new Vector2(x, y).clamp(
      scaledWidth - scenePadding,
      sceneWidth - scaledWidth + scenePadding,
      scaledHeight - scenePadding,
      sceneHeight - scaledHeight + scenePadding
    );
  }

  function onDragStart(ev: MouseEvent | TouchEvent) {
    if (!$introDone && !$dragIntroStarted) return;
    if ($panelContent) return;
    if ($clickedStory) return; // When story title is clicked, disable user interaction

    // Only handle events on the canvas with an exception for the story titles
    if (
      ev.target instanceof HTMLElement &&
      ev.target.tagName != "CANVAS" &&
      ev.target.className.indexOf("story-title") != 0
    ) {
      return;
    }

    stop();

    dragStartTime = Date.now();
    dragStart = new Vector2();
    dragPointer = new Vector2();
    dragStart = normalizeMouseEvent(ev);
    if (
      dragStart &&
      ((ev instanceof MouseEvent && ev.button === 0) ||
        !(ev instanceof MouseEvent))
    ) {
      dragging = true;
      isCameraMovingByUser.set(dragging);
      cursor.set("grabbing");
    }
  }

  function onDragStop(ev: MouseEvent | TouchEvent) {
    dragging = false;
    cursor.set("grab");

    dragStart = new Vector2();
    dragPointer = new Vector2();

    if (inertiaList.length >= 2) {
      const inertiaItemFirst = inertiaList[0];
      const inertiaItemLast = inertiaList[inertiaList.length - 1];

      inertia = Vector2.clone(inertiaItemLast.position)
        .subtractVector(inertiaItemFirst.position)
        .divide(5)
        .clamp(-12, 12, -12, 12); // Limit maxmimum movement
      inertiaTimer = 0;
      inertiaDuration = inertia.magnitude() / 8;
    } else if (Date.now() - dragStartTime < 250) {
      const mousePos = normalizeMouseEvent(ev);
      if (mousePos) {
        click.set(
          new Vector2(
            $viewport.left + mousePos.x / $zoom,
            $viewport.top + mousePos.y / $zoom
          )
        );
      }
    }
  }

  function normalizeMouseEvent(
    ev: MouseEvent | TouchEvent
  ): Vector2 | undefined {
    if (ev instanceof MouseEvent) {
      return new Vector2(ev.pageX, ev.pageY);
    } else if (ev instanceof TouchEvent && ev.touches.length > 0) {
      const firstTouch = ev.touches[0];
      return new Vector2(firstTouch.pageX, firstTouch.pageY);
    }
  }

  function onDragMove(ev: MouseEvent | TouchEvent) {
    if (!dragging) {
      return;
    }

    dragPointer = normalizeMouseEvent(ev);
  }
</script>

<svelte:window
  on:mousedown|passive={onDragStart}
  on:touchstart|passive={onDragStart}
  on:mouseup|passive={onDragStop}
  on:touchend|passive={onDragStop}
  on:mousemove={onDragMove}
  on:touchmove={onDragMove}
/>

<slot />
