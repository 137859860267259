import { Vector2 } from "../utils/vectors";
class MathC {
    distanceBetweenVectors(a, b) {
        return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
    }
    timeToSec(timecode) {
        if (!timecode)
            return;
        var time_array = timecode.split(":");
        time_array = time_array.reverse();
        var multiply = [1, 60, 3600];
        var seconds = 0;
        for (let i = 0; i < time_array.length; i++) {
            seconds = time_array[i] * multiply[i] + seconds;
        }
        return seconds;
    }
    lerp(a, b, p) {
        return a + p * (b - a);
    }
    slerp(a, b, p) {
        return a + (-(Math.cos(Math.PI * p) - 1) / 2) * (b - a);
    }
    clamp(a, min, max) {
        return Math.max(min, Math.min(max, a));
    }
    rotateVector(point, center, angle) {
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        const nx = cos * (point.x - center.x) + sin * (point.y - center.y) + center.x;
        const ny = cos * (point.y - center.y) - sin * (point.x - center.x) + center.y;
        return new Vector2(nx, ny);
    }
}
export default new MathC();
