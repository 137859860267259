export class Vector2 {
    constructor(x = 0, y = 0) {
        this.x = 0;
        this.y = 0;
        this.toString = () => {
            return `{ x: ${this.x}, y: ${this.y} }`;
        };
        this.x = x;
        this.y = y;
    }
    static clone(a) {
        return new Vector2(a.x, a.y);
    }
    magnitude() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    set(x, y) {
        this.x = x;
        this.y = y;
        return this;
    }
    setVector(a) {
        this.x = a.x;
        this.y = a.y;
        return this;
    }
    add(a) {
        this.x += a;
        this.y += a;
        return this;
    }
    addVector(a) {
        this.x += a.x;
        this.y += a.y;
        return this;
    }
    translate(x, y) {
        this.x = this.x + x;
        this.y = this.y + y;
        return this;
    }
    subtract(a) {
        this.x -= a;
        this.y -= a;
        return this;
    }
    subtractVector(a) {
        this.x -= a.x;
        this.y -= a.y;
        return this;
    }
    multiply(a) {
        this.x *= a;
        this.y *= a;
        return this;
    }
    multiplyVector(a) {
        this.x *= a.x;
        this.y *= a.y;
        return this;
    }
    divide(a) {
        this.x /= a;
        this.y /= a;
        return this;
    }
    divideVector(a) {
        this.x /= a.x;
        this.y /= a.y;
        return this;
    }
    clamp(xmin, xmax, ymin, ymax) {
        this.x = Math.max(xmin, Math.min(xmax, this.x));
        this.y = Math.max(ymin, Math.min(ymax, this.y));
        return this;
    }
}
