<script lang="ts">
  import { videoLoading } from "../data/asset-database";
</script>

<div class="loader" class:videoLoading={$videoLoading}>
  <div class="lds-dual-ring" />
</div>

<style lang="scss">
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-out;

    &.videoLoading {
      opacity: 1;
      transition: opacity 1.5s ease-in;
    }
  }
  .lds-dual-ring {
    position: absolute;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;
    left: 50%;
    top: 50%;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff rgba(255, 255, 255, 0.1) #fff rgba(255, 255, 255, 0.1);
    animation: lds-dual-ring 1.2s linear infinite;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.6), inset 0 0 10px 0 rgba(0, 0, 0, 0.6);
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
