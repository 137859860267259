import { derived } from "svelte/store";
function storeCombine(object) {
    const names = Object.keys(object);
    const writables = names.map((key) => object[key]);
    return derived(writables, (values) => values.reduce((store, value, i) => {
        store[names[i]] = value;
        return store;
    }, {}));
}
export default storeCombine;
