import { frameRateEnabled, noZoom, overviewEnabled, movableElements, storyHotspots, noIntro, noParallax, noPauseOnTouch, } from "../contexts/debug-context";
const debug = {
    all: false,
    frameRate: false,
    overview: false,
    intro: false,
    noZoom: false,
    movableObjects: false,
    noPauseOnTouch: false,
};
class DebugHelper {
    constructor() {
        this.enableProxy();
        this.checkUrlParams();
    }
    enableProxy() {
        window.debug = new Proxy(debug, {
            set: (_, key, value) => {
                switch (key) {
                    case "all":
                        frameRateEnabled.set(value);
                        overviewEnabled.set(value);
                        noZoom.set(value);
                        movableElements.set(value);
                        storyHotspots.set(value);
                        noParallax.set(value);
                        noPauseOnTouch.set(value);
                        break;
                    case "frameRate":
                        frameRateEnabled.set(value);
                        break;
                    case "overview":
                        overviewEnabled.set(value);
                        break;
                    case "noZoom":
                        noZoom.set(value);
                        break;
                    case "movableElements":
                        movableElements.set(value);
                        break;
                    case "storyHotspots":
                        storyHotspots.set(value);
                        break;
                    case "noIntro":
                        noIntro.set(value);
                        break;
                    case "noParallax":
                        noParallax.set(value);
                        break;
                    case "noPauseOnTouch":
                        noPauseOnTouch.set(value);
                        break;
                }
                return true;
            },
        });
    }
    checkUrlParams() {
        const { searchParams } = new URL(location.href);
        frameRateEnabled.set(searchParams.get("frameRate") === "true" ||
            searchParams.get("debugAll") === "true");
        overviewEnabled.set(searchParams.get("overview") === "true" ||
            searchParams.get("debugAll") === "true");
        noZoom.set(searchParams.get("noZoom") === "true" ||
            searchParams.get("debugAll") === "true");
        noParallax.set(searchParams.get("noParallax") === "true" ||
            searchParams.get("noParallax") === "true");
        movableElements.set(searchParams.get("movableElements") === "true" ||
            searchParams.get("debugAll") === "true");
        storyHotspots.set(searchParams.get("storyHotspots") === "true" ||
            searchParams.get("debugAll") === "true");
        noIntro.set(searchParams.get("noIntro") === "true" ||
            searchParams.get("debugAll") === "true");
        noPauseOnTouch.set(searchParams.get("noPauseOnTouch") === "true" ||
            searchParams.get("debugAll") === "true");
    }
}
export default new DebugHelper();
