<script lang="ts">
  import { overviewEnabled } from "../../contexts/debug-context";
  import { sceneHeight, sceneWidth } from "../../contexts/interactive-context";
  import renderable from "../../entities/renderable";

  $: if ($overviewEnabled) loadImage();

  let image: HTMLImageElement | null = null;

  function loadImage() {
    if (!!image) {
      return;
    }

    image = new Image();
    image.src = "/assets/sprites/overview.webp";
  }

  renderable(({ context }) => {
    if (!$overviewEnabled) {
      return;
    }

    if (!image) {
      return;
    }

    context.globalAlpha = 0.2;
    context.drawImage(image, 0, 0, sceneWidth, sceneHeight);
    context.globalAlpha = 1;
  });
</script>

<slot />
