<script lang="ts">
  import { imageDatabase } from "../data/asset-database";
  import SpriteRenderer from "./core/SpriteRenderer.svelte";
  import ParallaxObject from "../components/ParallaxObject.svelte";
  import Shimmer from "./Shimmer.svelte";
  import { sceneWidth, sceneHeight } from "../contexts/interactive-context";

  const z = -40;
</script>

<ParallaxObject x={sceneWidth / 2} y={sceneHeight / 2} {z} let:x={laxX} let:y={laxY}>
  <Shimmer easing={"easeInOutSine"} opacitySpeed={3} scaleSpeed={6} maxScaleOffset={0.03} let:opacity={opacity} let:scale={scale}>
    <SpriteRenderer
      sprite={imageDatabase.background}
      roundPixels={false}
      scale={scale}
      opacity={opacity}
      x={laxX}
      y={laxY}
    />
  </Shimmer>
</ParallaxObject>
<slot />
