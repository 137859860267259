<svelte:options accessors={true} />

<script lang="ts">
  import { movableElements } from "../../contexts/debug-context";
  import type { HTMLScalableImageElement } from "../../data/asset-database";
  import renderable from "../../entities/renderable";
  import { generateCachedImage } from "../../utils/cached-frame";
  import { Vector2 } from "../../utils/vectors";

  export let sprite: HTMLScalableImageElement;

  export let enabled = true;
  export let opacity = 1;
  export let scale = 1;
  export let roundPixels = true;
  export let anchor = new Vector2(0.5, 0.5);
  export let x = 0;
  export let y = 0;

  let spriteFrame: ImageBitmap | HTMLImageElement | null = null;
  let spriteSize = new Vector2();

  renderable({
    render({ context }) {
      if (!enabled) {
        return;
      }

      if (!spriteFrame) {
        return;
      }

      if (!opacity) {
        return;
      }

      const offsetX = x - anchor.x * spriteSize.x * scale;
      const offsetY = y - anchor.y * spriteSize.y * scale;
      const width = spriteSize.x * scale;
      const height = spriteSize.y * scale;

      context.globalAlpha = opacity;
      context.drawImage(
        spriteFrame,
        roundPixels ? Math.round(offsetX) : offsetX,
        roundPixels ? Math.round(offsetY) : offsetY,
        roundPixels ? Math.round(width) : width,
        roundPixels ? Math.round(height) : height
      );
      context.globalAlpha = 1;

      if ($movableElements) {
        context.strokeStyle = "#FF0000";
        context.strokeRect(offsetX, offsetY, width, height);
      }
    },
    setup() {
      generateCachedImage(sprite)
        .then((frame) => (spriteFrame = frame))
        .catch(() => {});

      const { width, height, scale } = sprite;

      spriteSize.set(Math.round(width * scale), Math.round(height * scale));
    },
  });
</script>
