<script lang="ts">
  export function start() {
    animate = true;
  }

  export function stop() {
    done = true;
  }

  $: done = false;
  $: animate = false;
</script>

<div class="container" class:animate class:done>
  <slot />
</div>

<style lang="scss" scoped>
  .container {
    opacity: 0;
    transition: all 0.4s ease-out;
    transform: translateY(50px);

    &.animate {
      opacity: 1;
      transform: translateY(0);
    }

    &.done {
      opacity: 0;
      transform: translateY(-50px);
    }
  }
</style>
