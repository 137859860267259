<script lang="ts">
  import SpriteRenderer from "../components/core/SpriteRenderer.svelte";
  import FloatingObject from "../components/FloatingObject.svelte";
  import ParallaxObject from "../components/ParallaxObject.svelte";
  import Shimmer from "../components/Shimmer.svelte";
  import { imageDatabase } from "../data/asset-database";

  const z = -20;

  type Star = {
    x: number;
    y: number;
    scale: number;
  };

  let stars: Star[] = [
    { x: 1200, y: 500, scale: 1 },
    { x: 1300, y: 600, scale: 0.6 },
    { x: 1100, y: 800, scale: 0.5 },
    { x: 1600, y: 400, scale: 0.6 },
    { x: 1750, y: 300, scale: 0.8 },
    { x: 1620, y: 480, scale: 0.5 },
    { x: 1900, y: 331, scale: 0.6 },
    { x: 2028, y: 572, scale: 0.7 },
    { x: 2187, y: 497, scale: 0.5 },
    { x: 2322, y: 436, scale: 0.6 },
    { x: 2438, y: 582, scale: 0.4 },
    { x: 2208, y: 752, scale: 0.6 },
    { x: 2400, y: 722, scale: 0.7 },
    { x: 2650, y: 761, scale: 0.8 },
    { x: 2321, y: 977, scale: 1 },
    { x: 2345, y: 1070, scale: 0.6 },
    { x: 2542, y: 1021, scale: 0.5 },
    { x: 2598, y: 828, scale: 0.6 },
    { x: 1008, y: 942, scale: 0.5 },
    { x: 1006, y: 1260, scale: 1 },
    { x: 1352, y: 761, scale: 0.6 },
    { x: 1408, y: 975, scale: 0.4 },
    { x: 1538, y: 984, scale: 0.7 },
    { x: 1379, y: 1123, scale: 0.8 },
    { x: 1595, y: 1149, scale: 0.7 },
    { x: 2050, y: 1296, scale: 0.5 },
    { x: 2253, y: 1256, scale: 1 },
    { x: 2575, y: 1218, scale: 0.7 },
    { x: 2834, y: 972, scale: 0.4 },
    { x: 2963, y: 1063, scale: 1 },
    { x: 2971, y: 1175, scale: 0.5 },
    { x: 3002, y: 1293, scale: 0.7 },
    { x: 1516, y: 1713, scale: 0.7 },
    { x: 1365, y: 1487, scale: 0.7 },
    { x: 1694, y: 1550, scale: 0.7 },
    { x: 2159, y: 1730, scale: 0.5 },
    { x: 2243, y: 1869, scale: 0.4 },
    { x: 2326, y: 1792, scale: 0.5 },
    { x: 2608, y: 1699, scale: 0.6 },
    { x: 2618, y: 1879, scale: 0.4 },
    { x: 2751, y: 1651, scale: 0.4 },
    { x: 2872, y: 1905, scale: 0.5 },
    { x: 2914, y: 1633, scale: 0.4 },
    { x: 3049, y: 1631, scale: 0.6 },
    { x: 3071, y: 1744, scale: 0.7 },
    { x: 3044, y: 1841, scale: 0.4 },
    { x: 3178, y: 1701, scale: 0.5 },
    { x: 3298, y: 1612, scale: 0.4 },
    { x: 3277, y: 1757, scale: 0.3 },
    { x: 3224, y: 1813, scale: 0.4 },
    { x: 3212, y: 1928, scale: 0.6 },
    { x: 3371, y: 1864, scale: 0.4 },
    { x: 3417, y: 1735, scale: 0.5 },
    { x: 3494, y: 1685, scale: 0.8 },
    { x: 3523, y: 1876, scale: 0.3 },
    { x: 3554, y: 1967, scale: 0.6 },
  ];
</script>

{#each stars as star}
<FloatingObject minOffsetX={-1} maxOffsetX={1} minOffsetY={-1} maxOffsetY={1} hoveringSpeed={2} x={star.x} y={star.y} let:x={floatX} let:y={floatY}>
  <ParallaxObject x={floatX} y={floatY} {z} let:x={laxX} let:y={laxY}>
    <Shimmer scale={star.scale} let:opacity={opacity} let:scale={scale}>
      <SpriteRenderer sprite={imageDatabase.star} x={laxX} y={laxY} roundPixels={false} opacity={opacity} scale={scale} />
    </Shimmer>
  </ParallaxObject>
</FloatingObject>
{/each}

<FloatingObject x={2620} y={1350} let:x={floatX} let:y={floatY}>
  <ParallaxObject x={floatX} y={floatY} {z} let:x={laxX} let:y={laxY}>
    <Shimmer opacitySpeed={3} let:opacity={opacity}>
      <SpriteRenderer sprite={imageDatabase.moon_half} x={laxX} y={laxY} roundPixels={false} opacity={opacity} />
    </Shimmer>
  </ParallaxObject>
</FloatingObject>

<FloatingObject x={1241} y={1574} let:x={floatX} let:y={floatY}>
  <ParallaxObject x={floatX} y={floatY} {z} let:x={laxX} let:y={laxY}>
    <Shimmer opacitySpeed={3} let:opacity={opacity}>
      <SpriteRenderer sprite={imageDatabase.moon_full} x={laxX} y={laxY} roundPixels={false} opacity={opacity}/>
    </Shimmer>
  </ParallaxObject>
</FloatingObject>